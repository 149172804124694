import React from "react"
import { Link, navigate } from 'gatsby';
import { useState } from 'react';
import { generateSmartDeliveryURLS } from '../../../utils/cloudinary';
import { getDate } from "../../../utils/getDate";
import { generateModifiedSlug } from '../../../utils/slug';
import BtnSlider from './btnSlide';
import './slider.css';

export default function Slider({ featuredData }) {

    const [slideIndex, setSlideIndex] = useState(1)
    const [index, setIndex] = useState(0);
    const [clickable, setClickable] = useState(true);
    const onSliderChange = (newIndex) => {
        setClickable(true);
        setIndex(newIndex);
    };

    const BlogLength = featuredData?.length

    const nextSlide = () => {
        if (slideIndex !== featuredData?.length) {
            setSlideIndex(slideIndex + 1)
            setIndex(index + 1);
        }
        else if (slideIndex === featuredData?.length) {
            setSlideIndex(1)
            setIndex(0);
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
            setIndex(index - 1);
        }
        else if (slideIndex === 1) {
            setSlideIndex(featuredData?.length)
            setIndex(BlogLength - 1);
        }
    }

    let deviceWidth;
    if (typeof window !== "undefined") {
        deviceWidth = window.innerWidth;

    }

    return (
        <div className='banner'>


            {featuredData && featuredData.map((post, idx) => {

                const bannerImg = post?.node?.feature_image
                return (
                    <div key={post.id} style={{ backgroundImage: `url(${generateSmartDeliveryURLS(post.node.feature_image)})` }} className={`main_banner_class ${slideIndex === idx + 1 ? "slide active-anim" : "slide"}`}>
                        <div className="overlay" />
                        <div className="global">
                            {/* <Link
                                href={`/${generateModifiedSlug(featuredData[index]?.node?.slug)}`}
                            > */}
                            <div className="banner_content" onClick={() => navigate(`/${generateModifiedSlug(featuredData[index]?.node?.slug)}`)}>
                                <h2>{post.node.title}</h2>
                                <p>{post.node.excerpt.substring(0, 250)}...</p>
                                <span>{getDate(post.node.updated_at_pretty)}</span>
                                <Link
                                    className="SignUp_modal_btn"
                                    href={`/${generateModifiedSlug(featuredData[index]?.node?.slug)}`}
                                >
                                    ЧИТАТЬ
                                </Link>
                            </div>
                            {/* </Link> */}

                        </div>
                    </div>
                );
            })}

            <BtnSlider moveSlide={prevSlide} direction={"prev"} />


            <BtnSlider moveSlide={nextSlide} direction={"next"} />


        </div>
    )
}