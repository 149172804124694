import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title, metaDescription, canonical }) => {

    return (
        <Helmet >
            <title>{!title || title === null || title === undefined ? "Exam Booster" : title}</title>
            {/* {metaDescription  && <meta name="description" content={metaDescription} />} */}
            {metaDescription === null || metaDescription === undefined ? null : (
                <meta name="description" content={metaDescription} />
            )}
            {/* <meta name="description" content={metaDescription === undefined ? "meta description" : metaDescription} /> */}
            {/* {metaTitle && <meta name="title" content={metaTitle} />} */}
            <link rel="canonical" href={canonical} />
        </Helmet>
    )
}

export default SEO