import React from "react"
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import AboutBlog from "../components/HomePage/AboutBlog/AboutBlog";
import Banner from '../components/HomePage/Banner';
import Blog from "../components/HomePage/Blog/Blog";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo/index"

const HomePage = ({ data }) => {

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const posts = data?.allGhostPosts?.edges;
  const indexBlock = data?.featuredHomepage.edges[0]

  const title = indexBlock?.node?.meta_title;
  const metaDescription = indexBlock?.node?.meta_description;

  return (
    <>
      <SEO title={title} metaDescription={metaDescription}
        canonical={url} />
      <Layout>

        <Banner featuredPages={data?.featuredPages} featuredPosts={data?.featuredPosts} />

        <AboutBlog indexBlock={indexBlock} />
        <div id="all_blogs">
          <Blog posts={posts} />
        </div>
      </Layout>
    </>
  );
};

// import React from 'react'
// import PropTypes from 'prop-types'
// import { graphql } from 'gatsby'

// import { Layout, PostCard, Pagination } from '../components/common'
// import { MetaData } from '../components/common/meta'

// /**
// * Main index page (home page)
// *
// * Loads all posts from Ghost and uses pagination to navigate through them.
// * The number of posts that should appear per page can be setup
// * in /utils/siteConfig.js under `postsPerPage`.
// *
// */
// const Index = ({ data, location, pageContext }) => {
//     const posts = data.allGhostPost.edges

//     return (
//         <>
//             {/* <MetaData location={location} /> */}
//             <Layout isHome={true}>
//                 <div className="container">
//                     <section className="post-feed">
//                         {posts.map(({ node }) => (
//                             // The tag below includes the markup for each post - components/common/PostCard.js
//                             <PostCard key={node.id} post={node} />
//                         ))}
//                     </section>
//                     <Pagination pageContext={pageContext} />
//                 </div>
//             </Layout>
//         </>
//     )
// }

HomePage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.object
};

export default HomePage;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPosts: allGhostPost(
      sort: {fields: published_at, order: DESC}
      limit: $limit
      skip: $skip
      filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}}
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    featuredHomepage: allGhostPage(filter: {slug: {eq: "exam_booster_home-page"}}) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
    featuredPosts: allGhostPost(
      filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}, featured: {eq: true}}
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    featuredPages: allGhostPage(filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}, featured: {eq: true}}) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
  }
`;
